import * as React from 'react';
import { Link } from 'gatsby';
import {
  Box,
  Center,
  Container,
  Flex,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import CustomButton from '../components/custom-button';
// import CustomCarousel from '../components/carousel';
import '../styles/index.scss';

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Enhanced Efficiency Crop Nutrition & Phosphate Management | Ostara"
        description="Explore innovative and sustainable fertilizers and nutrient recovery solutions from Ostara to improve yield and protect critical water sources."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Enhanced Efficiency Crop Nutrition"
      />
      <main>
        <Box w="100%" p={4} className="hero-container">
          <Container maxW={1400}>
            <Stack marginTop={[16, 20, 20, 150]} maxW="45rem" spacing={6}>
              <h1>
                Enhanced Efficiency Crop Nutrition and Phosphate Management
              </h1>
              <p>
                Innovative, efficient and sustainable fertilizers and nutrient
                recovery solutions to help feed the world.
              </p>
              <CustomButton to="/product" maxW={230}>
                EXPLORE PRODUCTS
              </CustomButton>
            </Stack>
          </Container>
        </Box>

        <Box w="100%" p={4} className="body-container">
          <Center>
            <h2>
              Ostara provides highly efficient phosphate fertilizers that
              improve yield and quality while protecting the environment.
            </h2>
          </Center>

          <Flex direction="column">
            <Container className="key-top-box" maxW={1410} />
            <Container
              className="card-box"
              maxW={1410}
              paddingY={5}
              paddingX={[5, 5, 8, 12]}
            >
              <SimpleGrid columns={{ md: 1, lg: 2 }} spacing={[5, 5, 10]}>
                <Flex className="card-item" direction="column">
                  <StaticImage
                    placeholder="transparent"
                    src="../images/fertilizers-min.jpeg"
                    alt="Ostara img"
                  />
                  <h3>Fertilizers</h3>
                  <p>
                    Ostara’s Crystal Green sustainable fertilizers deliver
                    phosphate, magnesium and nitrogen in a highly efficient,
                    plant available granule that enhances yield and overall crop
                    quality.
                  </p>
                  <CustomButton maxW={230} to="/product" marginTop="auto">
                    EXPLORE PRODUCTS
                  </CustomButton>
                </Flex>
                <Flex
                  className="card-item"
                  direction="column"
                  marginTop={[8, 6, 6, 0]}
                >
                  <StaticImage
                    placeholder="transparent"
                    src="../images/homepage-sustainability-min.jpeg"
                    alt="Ostara img"
                  />
                  <h3>Sustainability</h3>
                  <p>
                    Ostara is closing the loop for a more sustainable future.
                    With efficient fertilizers and phosphate management
                    technology, less nutrients are being released into the
                    environment.
                  </p>
                  <CustomButton
                    maxW={250}
                    to="/sustainability/"
                    marginTop="auto"
                  >
                    SUSTAINABILITY
                  </CustomButton>
                </Flex>
              </SimpleGrid>
            </Container>
            <Container className="key-down-box" maxW={1410} />
          </Flex>
          <Center className="body-heading">
            <Box maxW={600}>
              <h2>
                Improve yields and protect critical water sources while feeding
                the world.
              </h2>
            </Box>
          </Center>
          <Container className="key-top-2" maxW={1410} />
          <Container className="key-features" maxW={1410}>
            <SimpleGrid columns={{ sm: 1, md: 3, lg: 3, xl: 3 }} spacing={6}>
              <Link to="/applications/prevent-soil-fixation">
                <Box className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../images/home-icon1.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Improve
                    <br />
                    Yields
                  </p>
                </Box>
              </Link>
              <Link to="/applications/soil-tie-up">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../images/home-icon3.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Reduce Soil
                    <br />
                    Tie-Up
                  </p>
                </Flex>
              </Link>
              <Link to="/applications/reduce-nutrient-loss">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../images/home-icon2.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Avoid Nutrient
                    <br />
                    Runoff and Leaching
                  </p>
                </Flex>
              </Link>
              {/* <Link to="/applications/turf-ornaments">
                <Flex className="key-features--container">
                  <StaticImage
                    className="key-features--icon"
                    placeholder="transparent"
                    src="../images/home-icon4.svg"
                    alt="Ostara logo"
                  />
                  <p>
                    Enhance
                    <br />
                    Soil Health
                  </p>
                </Flex>
              </Link> */}
            </SimpleGrid>
          </Container>
        </Box>
        <Box w="100%" className="research-section-container">
          <div className="overlay" />
          <div className="research-section-wrapper">
            <Stack className="research-section" spacing={6}>
              <h3>Research-Backed Crop Results</h3>
              <p>
                Many years of university research and independent field trials
                show Crystal Green fertilizers have significant yield
                improvements across a wide variety of crops, compared to
                grower-standard practices.
              </p>
              <CustomButton maxW={200} to="/agronomy-research">
                VIEW CROP DATA
              </CustomButton>
            </Stack>
          </div>
        </Box>
        <Box w="100%" p={4} className="research-section-container2">
          <div className="research-section-wrapper">
            <Stack className="research-section" spacing={6}>
              <h3>Future-Proof Your Farm</h3>
              <p>
                Proven to reduce nutrient runoff and leaching, Crystal Green
                fertilizers enhance public confidence in farming by helping
                farmers meet emerging environmental pressures and improving the
                efficiency of phosphate with maximum yield results.
              </p>
              <CustomButton to="/sustainability" maxW={230}>
                WHY SUSTAINABILITY
              </CustomButton>
            </Stack>
          </div>
        </Box>

        {/* <Box w="100%" className="research-section-3-container">
          <Container className="research-section-3" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <Flex />
              <Flex>
                <Stack className="research-section-3-detail" spacing={6}>
                  <Link to="/404">
                    Meet Felix and see his carrot crop data after using Crystal
                    Green last season.
                  </Link>
                </Stack>
              </Flex>
            </SimpleGrid>
          </Container>
        </Box> */}

        {/*
        <Box w="100%" p={4} marginTop={20}>
          <Container className="carousel-section" maxW={1400}>
            <h4>Data & Insights</h4>
            <CustomCarousel className="carousel-container" />
          </Container>
        </Box> */}
        <Container className="body-cta-container" maxW={1400}>
          <Box className="body-cta">
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default IndexPage;
